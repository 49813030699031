import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { useLocalState } from './Layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'
import { RichText } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'
import ArticleInfo from './ArticleInfo'

const ARTICLE_HEADER_QUERY = graphql`
	query ArticleHeaderQuery {
		allFeedClerestoryPods {
			nodes {
        enclosure {
          type
          url
          length
        }
        guid
        id
        itunes {
          author
          duration
          episode
          season
          summary
          title
          image {
            attrs {
              href
            }
          }
        }
        link
        pubDate
			}
		}
	}
`

const ArticleHeader = ({ author, issueNumber, issueSlug, issueTitle, title, type, featuredImage, bio }) => {
  const staticData = useStaticQuery(ARTICLE_HEADER_QUERY),
    { data } = useMergePrismicPreviewData(staticData),
    { playEpisode, setCurrentEpisode } = useLocalState(),
    image = getImage(featuredImage),
    ratio = image
      ? image.width / image.height
      : undefined,
    width = image
      ? ratio >= 1
        ? 384
        : Math.round(ratio * 384)
      : undefined,
    typeLink =
      type === 'interview'
        ? '/interviews'
        : type === 'essay'
          ? '/essays'
          : type === 'letter'
            ? `${issueSlug}`
            : '/photo-stories',
    episodeIndex = data?.allFeedClerestoryPods?.nodes?.findIndex(episode =>
      episode?.itunes?.title === title?.text
        || episode?.itunes?.title === `${title?.text} by ${author?.document?.data?.author_name?.text}`
      ),
    episode = episodeIndex > -1 && data.allFeedClerestoryPods.nodes[episodeIndex]

	return [
    <ArticleInfo
      author={author}
      issueNumber={issueNumber}
      issueTitle={issueTitle}
      issueSlug={issueSlug}
      key="article-info"
      type={type}
    />,
		<div key="article-header" className="small-container pt-24 lg:pt-48">
			<h1 className="max-w-md mx-auto text-center no-hyphens">{title.text}</h1>
      <div className="lg:hidden mx-auto mt-8 mb-12 text-center">
        {issueNumber && issueSlug && issueTitle && (
          <div>
            <TransitionLink
              className="inline-block py-0.5 leading-none no-underline"
              to={issueSlug ?? ``}
              exit={pageTransitionOut}
              entry={pageTransitionIn}
            >
              <h5>
                ISSUE No. {issueNumber}{' '}
                <span className="italic font-serif text-match">
                  {issueTitle}
                </span>
              </h5>
            </TransitionLink>
          </div>
        )}
        {type && (
          <TransitionLink
            className="inline-block py-0.5 leading-none no-underline"
            to={`${typeLink}` ?? ``}
            exit={pageTransitionOut}
            entry={pageTransitionIn}
          >
            <h5 className="inline uppercase">
              {type === `letter` ? `Editor’s Letter` : type}
            </h5>
          </TransitionLink>
        )}
        {author?.document && (
          <>
            {` `}
            <TransitionLink
              className="inline py-0.5 leading-none no-underline"
              to={linkResolver(author.document)}
              exit={pageTransitionOut}
              entry={pageTransitionIn}
            >
              <h5 className="inline">
                BY{' '}
                <span className="font-serif text-match italic">
                  {author.document?.data?.author_name?.text}
                </span>
              </h5>
            </TransitionLink>
          </>
        )}
      </div>
			{image
        ? <GatsbyImage
            alt={featuredImage?.alt ?? ``}
            className="block! mx-auto my-12 max-w-sm max-h-96"
            objectFit="contain"
            image={image}
            sizes={`${width}px`}
          />
        : <div className="my-12" />
      }
      {episode && (
        <div className="my-8">
          <h5>
            This {type === `letter` ? `Editor’s Letter` : type} has an associated podcast episode.{` `}
            <a
              className="cursor-pointer"
              onClick={() => {
                setCurrentEpisode({ ...episode, autoplay: true })
                playEpisode()
              }}
              tabIndex="0"
            >
              Listen now
            </a>
            .
          </h5>
        </div>
      )}
			{bio && (
        <RichText
          htmlSerializer={htmlSerializer}
          render={bio.raw}
        />
			)}
		</div>
]
}

export default ArticleHeader
