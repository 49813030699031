import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'
import ArticleCard from './ArticleCard'
import linkResolver from '../utils/linkResolver'

const TableOfContents = ({ articles, author, naked, tight, type }) => {
	let gridClasses = `w-full max-w-xxs sm:max-w-xs md:max-w-xl mt-16 mx-auto grid md:grid-cols-2 items-center gap-y-20 md:gap-x-36`,
    articleCount = 0

	if (tight) gridClasses = `${gridClasses} md:gap-y-24`
	else gridClasses = `${gridClasses} md:gap-y-48 md:mt-48`

  const articleCards = articles
    .map((article, index, array) => {
      // Short circuit if article does not exist
      const art = article?.article ?? article
      if(!(art && art?.uid)) return null
      
      // Short circuit if index does not match index
      const articleIndex = array.findIndex(a => (a?.article?.uid ?? a?.uid) === art.uid)
      if(index !== articleIndex) return null
      
      // Short circuit if prop type does not match article type
      const type = art.type
      if(type && type !== type) return null

      // Short circuit if prop author does not match article author
      const articleAuthor = art?.document?.data?.author?.uid
      if(author && author !== articleAuthor) return null

      articleCount++

      return (
        <ArticleCard
          key={art.id ?? index}
          article={art}
        />
      )
    })

	return articleCount
    ? naked
      ? articleCards
      : (
        <div className="large-container my-48">
          <div className={gridClasses}>
            {articleCards}
          </div>
        </div>
      )
    : null
}

const TableOfIssueContents = ({ issues, tight, type }) => {
	let gridClasses = `w-full max-w-xxs sm:max-w-xs md:max-w-xl mt-16 mx-auto grid md:grid-cols-2 items-center gap-y-20 md:gap-x-36`

	if (tight) gridClasses = `${gridClasses} md:gap-y-24`
	else gridClasses = `${gridClasses} md:gap-y-48 md:mt-48`

	return (
		<div className="large-container pt-24 lg:pt-48 mb-48">
			<div className={gridClasses}>
				{issues.map((issue, i) => 
          <TableOfContents
            articles={issue?.data?.articles}
            key={`contents-${issue.id}`}
            naked
            tight
            type={type}
          />
        )}
			</div>
		</div>
	)
}

export default TableOfContents
export { TableOfContents, TableOfIssueContents }
