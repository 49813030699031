import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import { useLocalState } from '../components/Layout'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'
import ArticleHeader from '../components/ArticleHeader'
import TableOfContents from '../components/TableOfContents'
import { Logo } from '../components/Logo'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'
import { useTransitionClasses } from "../hooks/useTransitionClasses"

const AuthorPage = ({ data, pageContext, transitionStatus }) => {      
  const { previewIssue } = useLocalState(),
    { prismicAuthor, ...rest} = data,
    issues = previewIssue
      ? [previewIssue, ...(rest?.allPrismicIssue?.nodes ?? [])]
      : rest?.allPrismicIssue?.nodes ?? [],
    articles = issues.flatMap(issue => {
      let letter
      if(issue?.data?.editors_letter_author?.uid === prismicAuthor?.uid) {
        letter = {
          article: {
            type: `letter`,
            uid: issue.uid,
            document: {
              data: {
                author: prismicAuthor,
                excerpt: issue?.data?.editors_foreword,
                featured_image: issue?.data?.featured_image,
                title: issue?.data?.editors_title?.text === ``
                  ? { text: `Issue No. ${issue.data.issue_number.text} ${issue.data.issue_title.text}` }
                  : issue?.data?.editors_title,
              },
              type: `letter`,
              uid: issue.uid,
            }
          },
        }
      }
      return letter
        ? [letter, ...issue?.data?.articles]
        : issue?.data?.articles
    }),
    transitionClasses = useTransitionClasses({ transitionStatus })

	return (
		<div className={transitionClasses}>
			<Seo
        description={prismicAuthor.data.author_bio?.text}
        image={prismicAuthor.data.author_image}
        title={`Contributor ${prismicAuthor.data.author_name.text}`}
      />
			<article>
				<ArticleHeader
					title={prismicAuthor.data.author_name}
					featuredImage={prismicAuthor.data.author_image}
					bio={prismicAuthor.data.author_bio}
				/>
			</article>
			<div className="large-container">
        <TransitionLink className="block w-min my-48 mx-auto" to="/" entry={pageTransitionIn} exit={pageTransitionOut}>
          <Logo className="w-12 h-auto mx-auto text-blue fill-current" />
        </TransitionLink>
				<TableOfContents articles={articles} author={prismicAuthor.uid} tight />
			</div>
      <Footer />
		</div>
	)
}

export default withPrismicPreview(AuthorPage, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY,
  linkResolver,
  htmlSerializer,
}])

export const AuthorQuery = graphql`
	query AuthorQuery($id: String!) {
		prismicAuthor(id: { eq: $id }) {
      _previewable
      id
      type
      uid
			data {
				author_name {
					text
				}
				author_image {
          alt
          gatsbyImageData
				}
				author_bio {
					html
          text
          raw
				}
			}
		}
		allPrismicIssue(
			sort: { order: DESC, fields: data___issue_number___text }
		) {
			nodes {
        _previewable
				id
				uid
        type
				data {
					editors_foreword {
						html
						text
            raw
					}
          editors_letter_author {
            id
            uid
          }
          editors_title {
            text
          }
					issue_number {
						text
					}
					issue_title {
						text
					}
					featured_image {
            alt
            gatsbyImageData
					}
					articles {
						article {
							uid
              type
							document {
								... on PrismicEssay {
									id
									uid
									data {
                    author {
                      id
                      type
                      uid
                    }
										excerpt {
											text
										}
										title {
											text
										}
										featured_image {
                      alt
                      gatsbyImageData
										}
									}
									type
								}
								... on PrismicInterview {
									id
									uid
									type
									data {
                    author {
                      id
                      type
                      uid
                    }
										excerpt {
											text
										}
										title {
											text
										}
										featured_image {
                      alt
                      gatsbyImageData
										}
									}
								}
								... on PrismicPhotoStory {
									id
									uid
									type
									data {
                    author {
                      id
                      type
                      uid
                    }
										excerpt {
											text
										}
										title {
											text
										}
										featured_image {
                      alt
                      gatsbyImageData
										}
									}
								}
                ... on PrismicPoem {
									id
									uid
									data {
                    author {
                      id
                      type
                      uid
                    }
										excerpt {
											text
										}
										title {
											text
										}
										featured_image {
                      alt
                      gatsbyImageData
										}
									}
									type
								}
							}
						}
					}
				}
			}
		}
	}
`