import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'
import { RichText } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'

const ArticleInfo = ({
	issueNumber,
	issueTitle,
	type,
	author,
	issueSlug,
}) => {
	const { author_bio, author_name } = author?.document?.data || {}
	const archiveLink =
		type === 'interview'
			? '/interviews'
			: type === 'essay'
			? '/essays'
			: type === 'letter'
			? `${issueSlug}`
			: '/photo-stories'
	return (
		<div className="sticky top-0 px-5 py-4 pb-0 z-50 hidden lg:block float-left">
      <div>
        <TransitionLink
          className="inline-block py-0.5 leading-none no-underline"
          to={issueSlug ?? ``}
          exit={pageTransitionOut}
          entry={pageTransitionIn}
          >
          <h5>
            ISSUE No. {issueNumber}{' '}
            <span className="italic font-serif text-match">
              {issueTitle}
            </span>
          </h5>
        </TransitionLink>
      </div>
      <div>
        <TransitionLink
          className="inline-block py-0.5 leading-none no-underline"
          to={`${archiveLink}` ?? ``}
          exit={pageTransitionOut}
          entry={pageTransitionIn}
        >
          <h5 className="inline uppercase">
            {type === `letter` ? `Editor’s Letter` : type}
          </h5>
        </TransitionLink>
        {author?.document && (
          <>
            <h5 className="inline">
              <span className="py-0.5">
                {` `}BY{` `}
              </span>
            </h5>
            <TransitionLink
              className="inline py-0.5 leading-none no-underline"
              to={linkResolver(author.document) ?? ``}
              exit={pageTransitionOut}
              entry={pageTransitionIn}
            >
              <h5 className="inline">
                <span className="font-serif text-match italic">
                  {author_name.text}
                </span>
              </h5>
            </TransitionLink>
          </>
        )}
      </div>
      {author?.document && (
        <h5 className="mt-0.5 max-w-xxs">
          <span
            className="author-bio font-serif text-match"
          >
            <RichText
              Component="span"
              htmlSerializer={htmlSerializer}
              render={author_bio.raw}
            />
          </span>
        </h5>
      )}
		</div>
	)
}

export default ArticleInfo
export { ArticleInfo }
