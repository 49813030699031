import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'
import linkResolver from '../utils/linkResolver'

const ArticleCard = ({ article }) => {
	const { data, type, url } = article?.document ?? article ?? {},
		image = getImage(data?.featured_image)

	if (!data) {
		return null
	}

	return (
    <TransitionLink
      to={linkResolver(article) ?? ``}
      className="group relative text-black!"
      exit={pageTransitionOut}
      entry={pageTransitionIn}
    >
      <GatsbyImage
        alt={data?.featured_image?.alt ?? ``}
        className="max-h-xxs sm:max-h-xs md:max-h-none group-hover:opacity-80 group-focus:opacity-80 transition-opacity duration-300"
        imgClassName="object-contain! md:object-cover!"
        image={image}
        sizes="216px, (min-width: 640px) 320px, (min-width: 768px) 216px"
      />
      <div className="relative mx-auto max-w-full md:min-w-full" style={{
        width: `${100 * image?.width / image?.height}%`,
      }}>
        <div className="mt-1.5 font-sans text-xs uppercase group-hover:opacity-0 group-focus:opacity-0 transition-opacity duration-300 ease-in-out">
          {type === `letter`
            ? `Editor’s Letter`
            : type?.replace(/_/g, ' ')
          }
          {` `}
          <span className="font-serif text-match italic normal-case overflow-ellipsis overflow-hidden">
            {data?.title?.text}
          </span>
        </div>
        <p className="absolute top-0 left-0 font-sans text-xs opacity-0 group-hover:opacity-100 group-focus:opacity-100 transition-opacity duration-300 ease-in-out ellipsis">
          {data?.excerpt?.text}
        </p>
      </div>
		</TransitionLink>
	)
}

export default ArticleCard
